import { useRef, useState } from 'react'
import {
  Avatar,
  Box,
  ButtonBase,
  Menu,
  MenuItem,
  makeStyles,
  Typography,
} from '@material-ui/core'

import ChangeAccount from '../ChangeAccount'

import styles from './styles'

import * as service from 'service'

import useAuth from 'hooks/useAuth'

import { routes } from 'Routes'

const useStyles = makeStyles(styles)

const Account = () => {
  const classes = useStyles()
  const ref = useRef(null)
  const auth = useAuth()
  const [isOpen, setOpen] = useState(false)
  const [isChangeAccountOpen, setChangeAccountOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpenChangeAccount = () => {
    setChangeAccountOpen(true)
  }

  const handleLogout = () => {
    service.intermediador.auth.logout()
    auth.setAuth(false)
    service.history.push(routes.root)
  }
  const handleSettings = () => {
    service.history.push(routes.settings.main)
  }

  const avatarLetters = ((auth.user && auth.user.name) || 'Usuário')
    .split(' ')
    .map((word) => word[0])
    .join('')

  return (
    <>
      <Box display="flex">
        <Box
          display="flex"
          alignItems="center"
          component={ButtonBase}
          onClick={handleOpen}
          ref={ref}
        >
          <Avatar className={classes.avatar}>
            {avatarLetters[0]}
            {avatarLetters[avatarLetters.length - 1]}
          </Avatar>
        </Box>

        <Menu
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          keepMounted
          PaperProps={{ className: classes.popover }}
          getContentAnchorEl={null}
          anchorEl={ref.current}
          open={isOpen}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="body1" color="textPrimary">
              {(auth.user && auth.user?.name) || 'Usuário'}
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {(auth.user && auth.user?.account?.name) || 'Usuário'}
            </Typography>
          </Box>
          {auth?.accounts?.length > 1 && (
            <MenuItem onClick={handleOpenChangeAccount}>
              Mudar de conta
            </MenuItem>
          )}
          <MenuItem onClick={handleSettings}>Configurações</MenuItem>
          <MenuItem onClick={handleLogout}>Sair</MenuItem>
        </Menu>

        <ChangeAccount
          open={isChangeAccountOpen}
          setOpen={setChangeAccountOpen}
        />
      </Box>
    </>
  )
}

export default Account
