import {
  Grid,
  TextField,
  makeStyles,
  Box,
  Typography,
  Button,
} from '@material-ui/core'

import { useForm, Controller } from 'react-hook-form'
import { MoneyInput } from 'components'

import styles from './styles'
import * as service from 'service'
import schema from './schema'

import { useHistory } from 'react-router-dom'
import useSnackbar from 'hooks/useSnackbar'

const useStyles = makeStyles(styles)

const WithdrawsForm = () => {
  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      amount: '',
    },
  })

  const history = useHistory()
  const snackbar = useSnackbar()
  const classes = useStyles()

  const onSubmit = async (data) => {
    try {
      let response = await service.intermediador.withdraws.create({
        amount: data.amount,
      })

      if (response?.data?.withdraw?.status === 'canceled') {
        snackbar.open({
          message: `Falha ao processar pagamento do saque`,
          variant: 'error',
        })
      } else {
        snackbar.open({
          message: `Solicitação de saque criada com sucesso`,
          variant: 'success',
        })
      }

      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } catch (error) {
      snackbar.open({
        message: 'Erro ao criar solicitação de saque',
        variant: 'error',
      })
    }
  }

  return (
    <Grid item sm={12} xs={12}>
      <form id="withdrawForm" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={8}>
          <Grid item sm={12} xs={12}>
            <Box mt={10} style={{ marginTop: '20px' }}>
              <Typography variant="h6" className={classes.colorBlue}>
                Novo saque
              </Typography>
            </Box>
            <Box mt={2}>
              <Controller
                as={
                  <TextField
                    label="Valor do saque (R$)"
                    type="text"
                    color="primary"
                    error={!!errors.amount}
                    helperText={errors?.amount?.message}
                    className={classes.roundedTextField}
                    fullWidth
                    InputProps={{
                      inputComponent: MoneyInput,
                    }}
                  />
                }
                control={control}
                name="amount"
                mode="onBlur"
                size="small"
              />
            </Box>
            <Box mt={3} display="flex" justifyContent="flex-start">
              <Box pr={1}>
                <Button type="button" onClick={() => history.goBack()}>
                  Voltar
                </Button>
              </Box>
              <Box>
                <Button
                  form="withdrawForm"
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  Sacar
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Grid>
  )
}

export default WithdrawsForm
