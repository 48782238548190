import { Container, Page, Header } from 'components'
import { makeStyles, Grid } from '@material-ui/core'
import { SettingsIcon } from './components'

import styles from './styles'
import constants from 'constants/index'

import { routes } from 'Routes'

const useStyles = makeStyles(styles)

const SettingsMain = () => {
  const classes = useStyles()

  return (
    <Page className={classes.root} title="Configurações">
      <Container maxWidth="sm">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Header name="Configurações" route={routes.home} />
          </Grid>
          <Grid container spacing={6}>
            {constants.settingOptions.ITEMS &&
              constants.settingOptions.ITEMS.map((item) => (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <SettingsIcon
                    key={item.title}
                    icon={item.icon}
                    title={item.title}
                    subtitle={item.subtitle}
                    href={item.href}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default SettingsMain
