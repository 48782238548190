const styles = (theme) => ({
  planBox: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 20,
    padding: 8,
  },
  noPlanBox: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
  },
  planName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 'fit-content',
    maxWidth: '100%',
    marginBottom: 8,
  },
  iconButton: {
    display: 'flex',
    alignSelf: 'flex-start',
    padding: '0px',
    width: 50,
    height: 50,
  },
  descriptionText: {
    display: '-webkit-box',
    '-webkit-line-clamp': '3' /** número de linhas que você quer exibir */,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
    marginBottom: 8,
  },
  notPublicBox: {
    backgroundColor: '#e8ecec',
  },
})

export default styles
