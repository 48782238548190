import { makeStyles, Typography, Box, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import styles from './styles'

const useStyles = makeStyles(styles)

const BoxButton = ({
  iconName,
  nameButton,
  sizeIcon,
  textIconColor,
  backgroundColor,
  routes,
}) => {
  const classes = useStyles()
  const history = useHistory()

  const CircleComponent = ({ children, color }) => {
    return (
      <Box
        mb={2}
        className={classes.circle}
        style={{
          backgroundColor: color,
        }}
      >
        {children}
      </Box>
    )
  }

  const handleButtonClick = () => {
    history.push(routes)
  }

  return (
    <Button
      onClick={handleButtonClick}
      className={classes.boxButton}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'start',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <CircleComponent color={backgroundColor}>{iconName}</CircleComponent>
      <Typography
        variant="body2"
        className={classes.textButton}
        style={{ color: textIconColor, marginTop: '12px' }}
      >
        {nameButton}
      </Typography>
    </Button>
  )
}

// const getIconComponent = (iconName, size, color) => {
//   switch (iconName) {
//     case 'ArrowUpIcon':
//       return <ArrowUpIcon size={size} color={color} />
//     case 'ArrowDownIcon':
//       return <ArrowDownIcon size={size} color={color} />
//     case 'BriefcaseIcon':
//       return <BriefcaseIcon size={size} color={color} />
//     case 'ChevronRightIcon':
//       return <ChevronRightIcon size={size} color={color} />
//     case 'CheckIcon':
//       return <CheckIcon size={size} color={color} />
//     case 'DollarSignIcon':
//       return <DollarSignIcon size={size} color={color} />
//     case 'FileTextIcon':
//       return <FileTextIcon size={size} color={color} />
//     case 'LoaderIcon':
//       return <LoaderIcon size={size} color={color} />
//     case 'LinkIcon':
//       return <LinkIcon size={size} color={color} />
//     case 'LayersIcon':
//       return <LayersIcon size={size} color={color} />
//     case 'UploadIcon':
//       return <UploadIcon size={size} color={color} />
//     case 'ArrowDownCircleIcon':
//       return <ArrowDownCircleIcon size={size} color={color} />
//     case 'PieChartIcon':
//       return <PieChartIcon size={size} color={color} />
//     case 'PhoneIcon':
//       return <PhoneIcon size={size} color={color} />
//     case 'PlusIcon':
//       return <PlusIcon size={size} color={color} />
//     case 'RefreshCcwIcon':
//       return <RefreshCcwIcon size={size} color={color} />
//     case 'SettingsIcon':
//       return <SettingsIcon size={size} color={color} />
//     case 'SendIcon':
//       return <SendIcon size={size} color={color} />
//     case 'UsersIcon':
//       return <UsersIcon size={size} color={color} />
//     case 'TrendingUpIcon':
//       return <TrendingUpIcon size={size} color={color} />
//     default:
//       return null
//   }
// }

export default BoxButton
