import { useState } from 'react'

import { Grid, makeStyles, Typography, Box, Button } from '@material-ui/core'
import { Container, Page } from 'components'
import { Balance, BoxButton, ListCard } from './components'

import styles from './styles'
import { routes } from 'Routes'
import { reverse } from 'named-urls'
import { useHistory } from 'react-router-dom'

import constants from 'constants/index'

import useFetch from 'hooks/useFetch'
import * as service from 'service'

const useStyles = makeStyles(styles)

const Home = () => {
  const classes = useStyles()
  const history = useHistory()

  const { response } = useFetch(service.intermediador.orders.get, {
    page: 1,
    perPage: 3,
  })

  return (
    <Container maxWidth="md">
      <Page className={classes.root} title="Início">
        <Grid container>
          <Grid item lg={12} sm={12} xs={12}>
            <Balance />
          </Grid>
          <Grid container spacing={8}>
            {Object.entries(constants.functionalities.ITEMS).map(
              ([itemType, items], index) => {
                let showLastTransactions = index === 0 // Apenas na primeira iteração
                return (
                  <Grid container spacing={8} key={itemType}>
                    <Grid item xs={12} mt={4}>
                      <Box mt={8}>
                        <Typography variant="h6" color="textPrimary">
                          {items.title}
                        </Typography>
                      </Box>
                    </Grid>
                    {Object.entries(items).map(([itemName, itemInfo]) => {
                      if (itemInfo.view && itemName !== 'title') {
                        return (
                          <Grid item xs={6} sm={4} md={3} lg={3} key={itemName}>
                            <BoxButton
                              sizeIcon={16}
                              iconName={itemInfo.icon}
                              textIconColor={itemInfo.color}
                              backgroundColor={itemInfo.background}
                              nameButton={itemInfo.name}
                              routes={itemInfo.route}
                            />
                          </Grid>
                        )
                      } else {
                        return null
                      }
                    })}
                    {showLastTransactions && (
                      <Grid item xs={12}>
                        <Box mt={8}>
                          <Typography variant="h6" color="textPrimary">
                            Últimas transações
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                    {showLastTransactions && (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box className={classes.container}>
                          {response?.data?.orders &&
                            response?.data?.orders.map((order) => (
                              <ListCard
                                key={order?.token}
                                status={order?.status}
                                route={order?.token}
                                data={order}
                              />
                            ))}
                        </Box>
                        <Box>
                          <Button
                            color="primary"
                            onClick={() =>
                              history.push(reverse(routes?.orders?.main))
                            }
                          >
                            Ver mais
                          </Button>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                )
              },
            )}
          </Grid>
        </Grid>
      </Page>
    </Container>
  )
}

export default Home
