import {
  Box,
  makeStyles,
  Grid,
  Typography,
  IconButton,
  Button,
  Tooltip,
} from '@material-ui/core'
import {
  Eye as EyeIcon,
  EyeOff as EyeOffIcon,
  ChevronRight as ChevronRightIcon,
} from 'react-feather'

import styles from './styles'
import clsx from 'clsx'
import { routes } from 'Routes'
import { reverse } from 'named-urls'
import * as service from 'service'

import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(styles)

const PlansList = ({ plans, refresh }) => {
  const history = useHistory()
  const classes = useStyles()

  const updatePlan = async (plan) => {
    await service.intermediador.plans.update({
      planId: plan?.id,
      plan: {
        public: !plan?.public,
      },
    })

    refresh()
  }

  return (
    <Grid container spacing={8}>
      {plans?.length === 0 ? (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box className={classes.noPlanBox}>Nenhum plano encontrado</Box>
        </Grid>
      ) : (
        ''
      )}
      {plans?.map((plan) => (
        <Grid item lg={4} md={6} sm={6} xs={12} key={plan?.id}>
          <Box
            display="flex"
            className={clsx(classes.planBox, {
              [classes.notPublicBox]: !plan?.public,
            })}
          >
            <Grid container spacing={8}>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <IconButton
                  className={classes.iconButton}
                  color="secondary"
                  component="span"
                  onClick={() => {
                    updatePlan(plan)
                  }}
                >
                  {plan?.public ? (
                    <EyeIcon size={22} color="#325c81" />
                  ) : (
                    <EyeOffIcon size={22} color="#325c81" />
                  )}
                </IconButton>
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  width="100%"
                >
                  <Tooltip title={plan.name} placement="top-start">
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      className={classes.planName}
                    >
                      {plan.name}
                    </Typography>
                  </Tooltip>
                  <Typography
                    variant="body2"
                    className={classes.descriptionText}
                  >
                    {plan.description}
                  </Typography>
                  <Button
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      padding: '0px',
                      width: '90px',
                    }}
                    onClick={() => {
                      history.push(
                        reverse(routes.plans.edit, {
                          planId: plan?.id,
                        }),
                      )
                    }}
                  >
                    <Box display="flex" justifyContent="center">
                      Ver Mais <ChevronRightIcon size={22} />
                    </Box>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}

export default PlansList

{
  /* <Grid item lg={4} md={6} sm={6} xs={12} key={plan?.id}>
          <Box
            display="flex"
            className={clsx(classes.planBox, {
              [classes.notPublicBox]: !plan?.public,
            })}
          >

            <Box display="flex" flexDirection="column" width="90%">
              <Tooltip title={plan.name} placement="top-start">
                <Typography
                  variant="body1"
                  color="textPrimary"
                  className={classes.planName}
                >
                  {plan.name}
                </Typography>
              </Tooltip>
              <Typography variant="body2" className={classes.descriptionText}>
                {plan.description}
              </Typography>
              <Box display="flex" alignItems="flex-end">

              </Box>
            </Box>
          </Box>
        </Grid> */
}
