import { useState, useEffect } from 'react'

import { Container, Page } from 'components'
import Logo from 'images/logo-horizontal-y.png'
import LogoLeadLovers from 'images/leadlovers-marca.svg'

import { ThemeProvider, createTheme } from '@material-ui/core'
import checkoutTheme from 'theme/checkoutTheme'
import themeLeadLovers from 'theme/themeLeadLovers'

import {
  Grid,
  Hidden,
  makeStyles,
  Typography,
  Box,
  Button,
} from '@material-ui/core'

import {
  CheckoutPaidPayment,
  CheckoutPayment,
  ProductDetails,
} from './components'

import styles from './styles'
import * as service from 'service'
import constants from 'constants/index'
import { useLocation } from 'react-router-dom'

import history from 'service/history'

const useStyles = makeStyles(styles)

const CheckoutMain = ({ match }) => {
  const type = match.params.type
  const token = match.params.token
  const location = useLocation()
  const accountToken = location.state?.accountToken
  const [isLoading, setIsLoading] = useState(true)
  const [order, setOrder] = useState({})
  const classes = useStyles()
  const [step, setStep] = useState(1)
  const [customer, setCustomer] = useState({})

  const [theme, setTheme] = useState(null)

  const addPrice = (products) => {
    const listProduct = products
    for (let i = 0; i < 1; i++) {
      listProduct?.payment?.products.push({
        name: 'Desconto',
        description: 'desconto aplicado por pix ou boleto',
        price: -(sumProductValues(listProduct?.payment?.products) * 0.1),
        quantity: 1,
      })
    }

    return listProduct
  }

  const sumProductValues = (products) => {
    if (!products || products.length === 0) {
      return 0
    }

    const totalValue = products.reduce((acc, product) => {
      if (product.name !== 'Desconto') {
        const price = parseFloat(product.price)
        const quantity = parseInt(product.quantity, 10)

        if (!isNaN(price) && !isNaN(quantity)) {
          acc += price * quantity
        }
      }

      return acc
    }, 0)

    return totalValue
  }

  const applyDiscountAndAdd = (products) => {
    const listProduct = products
    const discountProduct = listProduct?.payment?.products.find(
      (product) => product.name === 'Desconto',
    )
    const filteredProducts = listProduct?.payment?.products.filter(
      (product) => product.name !== 'Desconto',
    )

    const total = filteredProducts.reduce((accumulator, product) => {
      return accumulator + parseFloat(product.price) * product.quantity
    }, 0)

    const discountValue = (total * 0.1).toFixed(2)

    if (discountProduct) {
      discountProduct.price = -parseFloat(discountValue)
    }

    return products
  }

  const getTheme = async (token) => {
    if (token?.seller?.token) {
      try {
        const response = await service.intermediador.accountCustomizations.show(
          'theme',
          token?.seller?.token,
        )
        const themeData = JSON.parse(response?.data.accountCustomization?.data)
        setTheme(createTheme(themeData))
        return null
      } catch (error) {
        if (token?.seller?.partnerGroup === 'leadlovers') {
          setTheme(createTheme(themeLeadLovers))
        } else {
          setTheme(createTheme(checkoutTheme))
        }
        return null
      }
    } else {
      if (token?.seller?.partnerGroup === 'leadlovers') {
        setTheme(createTheme(themeLeadLovers))
      } else {
        setTheme(createTheme(checkoutTheme))
      }
    }
  }

  useEffect(() => {
    const getResponse = async () => {
      try {
        const response = await service.intermediador.payments.show(
          type,
          token,
          accountToken,
        )

        type === 'order' || type === 'plan'
          ? setOrder(response?.data)
          : setOrder(addPrice(response?.data))
        getTheme(response?.data)
      } catch (error) {
        setOrder(false)
        return null
      } finally {
        setIsLoading(false)
        return null
      }
    }

    getResponse()
  }, [type, token, accountToken])

  const handleOrderUpdate = (updatedOrder) => {
    setOrder(
      applyDiscountAndAdd({
        ...order,
        payment: {
          ...updatedOrder,
          value: sumProductValues(updatedOrder?.products),
        },
      }),
    )
  }

  return (
    <div style={{ backgroundColor: theme?.palette?.background?.default }}>
      <Container maxWidth="md">
        <>
          {theme && (
            <ThemeProvider theme={theme || checkoutTheme}>
              {!isLoading && order && (
                <Page title="Pagamento">
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      {order?.seller?.partnerGroup === 'leadlovers' ? (
                        <img
                          alt="Logo"
                          src={LogoLeadLovers}
                          className={classes.logo}
                          width="100%"
                        />
                      ) : (
                        <img
                          alt="Logo"
                          src={Logo}
                          className={classes.logo}
                          width="100%"
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={7}>
                      {order?.payment?.status ===
                      constants.payments.STATUSES.PAID_STATUS ? (
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          style={{ height: '100%' }}
                        >
                          <CheckoutPaidPayment />
                        </Box>
                      ) : (
                        <CheckoutPayment
                          type={type}
                          token={token}
                          order={order}
                          customer={customer}
                          setCustomer={setCustomer}
                          step={step}
                          setStep={setStep}
                          loading={isLoading}
                        />
                      )}
                    </Grid>
                    <Hidden smDown>
                      <Grid item xs={6} md={1} sm={12} lg={1}></Grid>
                    </Hidden>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <ProductDetails
                        order={order?.payment}
                        type={type}
                        onOrderUpdate={handleOrderUpdate}
                      />
                    </Grid>
                  </Grid>
                </Page>
              )}
              {!isLoading && !order && (
                <Page title="Pagamento">
                  <Box>
                    <Grid container justify="center" spacing={4}>
                      <Grid item lg={6} xs={12}>
                        <Box>
                          <img
                            alt="Em desenvolvimento"
                            className={classes.image}
                            src="/images/404.svg"
                          />
                          <Typography variant="h3" color="textPrimary">
                            404: Página não encontrada
                          </Typography>
                          <Typography variant="subtitle2" color="textPrimary">
                            Você tentou algum caminho desconhecido ou link do
                            produto está inativo.
                          </Typography>
                          <Box>
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => history.goBack()}
                            >
                              Voltar
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Page>
              )}
            </ThemeProvider>
          )}
        </>
      </Container>
    </div>
  )
}

export default CheckoutMain
