import { useState } from 'react'
import { makeStyles, Box, Typography, Grow, Grid } from '@material-ui/core'
import { Pulse } from 'components'

import { useHistory } from 'react-router-dom'
import {
  ChevronDown as ChevronDownIcon,
  ChevronRight as ChevronRightIcon,
} from 'react-feather'

import styles from './styles'
import theme from 'theme'

const useStyles = makeStyles(styles)

const ListCard = ({
  iconByStatus = null,
  title = null,
  subtitle = null,
  hour = null,
  route,
  value = null,
  moreDetails,
  stepper = null,
  sendToDetails = true,
  pulseStatus = false,
  pulseEffect = false,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [isComponentVisible, setComponentVisible] = useState(false)

  const toggleComponent = () => {
    setComponentVisible(!isComponentVisible)
  }

  const componentStyle = {
    opacity: isComponentVisible ? 1 : 0,
    height: isComponentVisible ? 'auto' : 0,
    overflow: 'hidden',
    transition: 'opacity 0.5s ease, height 0.5s ease',
  }

  return (
    <>
      <Box
        className={classes.cardRow}
        onClick={sendToDetails && route && toggleComponent}
        mb={2}
      >
        <Box className={classes.cardCol}>
          <Box
            style={{ backgroundColor: iconByStatus.color }}
            className={classes.circle}
            mr={2}
          >
            {iconByStatus && iconByStatus.icon}
            {pulseStatus && <Pulse status={pulseStatus} effect={pulseEffect} />}
          </Box>

          <Box>
            <Typography variant="body1" className={classes.operation}>
              {title && title}
            </Typography>
            <Typography variant="body2" className={classes.description}>
              {subtitle && subtitle}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.cardCol}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            {value && (
              <div style={{ color: theme.palette.text.primary }}>{value}</div>
            )}
            <Typography variant="body2" className={classes.description}>
              {hour && hour}
            </Typography>
          </Box>
          {route && (
            <Box ml={2}>
              {sendToDetails === false ? (
                <ChevronRightIcon
                  onClick={() => history.push(route)}
                  style={{
                    cursor: 'pointer',
                    color: theme.palette.text.primary,
                  }}
                />
              ) : (
                <ChevronDownIcon
                  style={{
                    transform: isComponentVisible
                      ? 'rotate(180deg)'
                      : 'rotate(0deg)',
                    transition: 'transform 0.3s ease',
                    cursor: 'pointer',
                    color: theme.palette.text.primary,
                  }}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
      {sendToDetails && (
        <Box style={componentStyle}>
          <Grow in={isComponentVisible}>
            <Box className={classes.cardDetails} mb={6}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ overflow: 'auto' }}
                >
                  {stepper}
                </Grid>
                {moreDetails &&
                  moreDetails.map((row, index) => (
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      key={index}
                    >
                      <Typography variant="body1" color="textPrimary">
                        {row.label}
                      </Typography>
                      <span className={classes.end}>{row.value}</span>
                    </div>
                  ))}
              </Grid>
              {sendToDetails ? (
                <Box
                  mt={2}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{
                      color: theme.palette.primary.main,
                      cursor: 'pointer',
                    }}
                    onClick={() => history.push(route)}
                  >
                    Mais detalhes
                  </Typography>
                </Box>
              ) : (
                ''
              )}
            </Box>
          </Grow>
        </Box>
      )}
    </>
  )
}

export default ListCard
