const styles = (theme) => ({
  avatar: {
    height: 45,
    width: 45,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(1),
  },
  popover: {
    width: '400px',
  },
})

export default styles
