import api from 'service/api'

const intermediadorAPI = api.create('intermediador')

const get = async ({ ...params }) => {
  return await intermediadorAPI.get('/charges', { params })
}

const create = async ({ ...data }) => {
  return await intermediadorAPI.post('/charges', data)
}

const createFull = async ({ ...data }) => {
  return await intermediadorAPI.post('/charges/full', data)
}

const charges = {
  get,
  create,
  createFull,
}

export default charges
