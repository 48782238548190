import { Grid, Typography, Box, makeStyles, Button } from '@material-ui/core'
import {
  ChevronRight as ChevronRightIcon,
  DollarSign as DollarSignIcon,
} from 'react-feather'

import { useHistory } from 'react-router-dom'

import styles from './styles'
import theme from 'theme'
import { routes } from 'Routes'

const useStyles = makeStyles(styles)

const Home = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box mt={10} mb={10}>
          <Typography variant="h6" color="textPrimary">
            Como você quer cobrar o seu cliente?
          </Typography>
        </Box>
        <Button
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          style={{
            width: '100%',
            backgroundColor: theme.palette.background.paper,
          }}
          onClick={() => history.push(routes.charges.new)}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            style={{ width: '100%' }}
          >
            <Box display="flex" flexDirection="row">
              <Box
                p={theme.spacing(6)}
                mr={theme.spacing(10)}
                display="flex"
                alignItems="center"
                className={classes.iconBox}
              >
                <DollarSignIcon size={16} color="#235078" />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Typography
                  variant="body1"
                  style={{ fontWeight: 'bold', textTransform: 'capitalize' }}
                  color="textPrimary"
                >
                  Cobranças avulsas
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{
                    textAlign: 'left',
                    textTransform: 'capitalize',
                  }}
                >
                  Realize cobranças únicas.
                </Typography>
              </Box>
            </Box>
            <ChevronRightIcon size={16} color={theme.palette.text.primary} />
          </Box>
        </Button>
      </Grid>
    </Grid>
  )
}

export default Home
