const styles = (theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  },
  accountBox: {
    borderRadius: 20,
    backgroundColor: '#235078',
    height: '100%',
    padding: 10,
  },
  colorWhite: {
    color: theme.palette.white,
  },
})

export default styles
