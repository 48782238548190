const isMobile = window.innerWidth <= 600

const styles = (theme) => ({
  iconColor: {
    color: theme.palette.white,
  },
  cardRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardCol: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardDetails: {
    backgroundColor: theme.palette.background.paper,
    backdropFilter: 'blur(12px)',
    padding: '12px',
    borderRadius: '20px',
  },
  start: {
    textAlign: 'start',
  },
  end: {
    fontFamily: 'poppins',
    textAlign: isMobile ? 'start' : 'end',
    color: theme.palette.text.primary,
  },
  circle: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: theme.spacing(20),
    height: theme.spacing(20),
    [theme.breakpoints.between('xs', 'sm')]: {
      width: theme.spacing(20),
      height: theme.spacing(20),
    },
  },
  operation: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  description: {
    color: theme.palette.text.secondary,
  },
  price: {
    fontWeight: 'bold',
    textAlign: 'end',
    color: theme.palette.text.primary,
  },
  pointer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
})

export default styles
