import { Container, Page, LoadingBox, Header } from 'components'
import { PlansForm } from './components'
import { makeStyles, Grid } from '@material-ui/core'

import useFetch from 'hooks/useFetch'
import { routes } from 'Routes'

import styles from './styles'
import * as service from 'service'

const useStyles = makeStyles(styles)

const PlansEdit = ({ match }) => {
  const classes = useStyles()
  const { planId } = match.params

  const { response, isLoading } = useFetch(service.intermediador.plans.show, {
    planId,
  })

  return (
    <Page className={classes.root} title="Planos Recorrentes">
      <Container maxWidth="sm">
        {isLoading ? (
          <LoadingBox />
        ) : (
          <Grid container spacing={8}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Header name="Planos recorrentes" route={routes.plans.main} />
            </Grid>
            <Grid item lg={12} xs={12}>
              <PlansForm
                plan={response?.data?.plan}
                isLoading={isLoading}
                isEdit={true}
              />
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  )
}

export default PlansEdit
