import { makeStyles } from '@material-ui/core'

const styles = () => {
  return makeStyles((theme) => ({
    root: {
      backgroundColor: 'transparent',
      minHeight: '104vh',
      height: 'auto',
    },
  }))
}

export default styles
