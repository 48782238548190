import { Box, Typography } from '@material-ui/core'

import { ArrowLeft as ArrowLeftIcon } from 'react-feather'
import { useHistory } from 'react-router-dom'

import theme from 'theme'

const Header = ({ name, route = null }) => {
  const history = useHistory()

  return (
    <Box
      mt={theme.spacing(10)}
      mb={theme.spacing(10)}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <ArrowLeftIcon
        component="span"
        style={{
          cursor: 'pointer',
          marginRight: 40,
        }}
        onClick={() => {
          history.push(route)
        }}
        size={32}
        color={theme.palette.text.primary}
      />
      <Typography variant="h6" color="textPrimary">
        {name}
      </Typography>
    </Box>
  )
}

export default Header
