const styles = (theme) => ({
  boxButton: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '20px',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  iconBox: {
    backgroundColor: '#e2f4f9',
    borderRadius: theme.spacing(20),
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
})

export default styles
