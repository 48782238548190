import New from './ChargesNew'
import Main from './ChargesMain'
import BilletInfo from './ChargesBilletInfo'

const Charges = {
  New,
  Main,
  BilletInfo,
}

export default Charges
