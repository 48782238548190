import { Switch, Redirect, Route } from 'react-router-dom'
import { RouteWithLayout } from './components'

import {
  Main as MainLayout,
  Payment as PaymentLayout,
  Checkout as CheckoutLayout,
  PreviewCheckout as PreviewCheckoutLayout,
} from './layouts'
import { include } from 'named-urls'

import NotFound from 'views/NotFound'
import Forbidden from 'views/Forbidden'
import Home from 'views/Home'
import LandingPage from 'views/LandingPage'
import { OrdersMain, OrdersShow } from 'views/Orders'
import { BalanceControlsMain } from 'views/BalanceControls'
import { SettingsMain, SettingsImplementation } from 'views/Settings'
import { PlansMain, PlansEdit, PlansNew } from 'views/Plans'
import {
  SubscriptionsNew,
  SubscriptionsShow,
  SubscriptionsMain,
} from 'views/Subscriptions'
import { ProfilesDetail } from 'views/Profiles'
import { GenerateReport } from 'views/GenerateReport'
import { SplitMain } from 'views/Split'
import { SeparateReport } from 'views/SeparateReport'
import { BankAccountsMain } from 'views/BankAccounts'
import { NotificationsNew } from 'views/Notifications'
import {
  PaymentPage,
  CreditCardPayment,
  BilletPayment,
  PixPayment,
  CreditCardPaid,
  PaymentCustomerInfo,
} from 'views/Payments'
//import { StatementsMain } from 'views/Statements'
import { SnackbarProvider, OrderProvider } from 'providers'
import { CheckoutMain, CheckoutPlans } from 'views/Checkout'
import {
  PreviewCheckoutMain,
  PreviewCheckoutPlans,
} from 'views/PreviewCheckout'
import Charges from 'views/Charges'
import Withdraws from 'views/Withdraws'

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        path={routes.home}
        component={Home}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.root}
        component={LandingPage}
        provider={SnackbarProvider}
        exact
      />
      <RouteWithLayout
        path={routes.balanceControls}
        component={BalanceControlsMain}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.orders.main}
        component={OrdersMain}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.orders.show}
        component={OrdersShow}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.charges.main}
        component={Charges.Main}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.charges.new}
        component={Charges.New}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.charges.billetInfo}
        component={Charges.BilletInfo}
        layout={MainLayout}
        exact
        auth
      />
      {/*<RouteWithLayout
        path={routes.statements.main}
        component={StatementsMain}
        layout={MainLayout}
        exact
        auth
  />*/}
      <RouteWithLayout
        path={routes.withdraws.new}
        component={Withdraws.New}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.settings.main}
        component={SettingsMain}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.settings.implementation}
        component={SettingsImplementation}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.settings.profile}
        component={ProfilesDetail}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.settings.generateReport}
        component={GenerateReport}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.settings.bankAccount}
        component={BankAccountsMain}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.settings.notifications}
        component={NotificationsNew}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.payments.main}
        component={PaymentCustomerInfo}
        layout={PaymentLayout}
        exact
        redirect={false}
        provider={OrderProvider}
      />
      <RouteWithLayout
        path={routes.payments.details}
        component={PaymentPage}
        layout={PaymentLayout}
        exact
        redirect={false}
        provider={OrderProvider}
      />
      <RouteWithLayout
        path={routes.payments.credit}
        component={CreditCardPayment}
        layout={PaymentLayout}
        exact
        redirect={false}
        provider={OrderProvider}
      />
      <RouteWithLayout
        path={routes.payments.billet}
        component={BilletPayment}
        layout={PaymentLayout}
        exact
        redirect={false}
        provider={OrderProvider}
      />
      <RouteWithLayout
        path={routes.payments.pix}
        component={PixPayment}
        layout={PaymentLayout}
        exact
        redirect={false}
        provider={OrderProvider}
      />
      <RouteWithLayout
        path={routes.payments.creditPaid}
        component={CreditCardPaid}
        layout={PaymentLayout}
        exact
        redirect={false}
        provider={OrderProvider}
      />
      <RouteWithLayout
        path={routes.checkoutPlans}
        component={CheckoutPlans}
        layout={CheckoutLayout}
        exact
        redirect={false}
        provider={OrderProvider}
      />
      <RouteWithLayout
        path={routes.checkout.main}
        component={CheckoutMain}
        layout={CheckoutLayout}
        exact
        redirect={false}
        provider={OrderProvider}
      />
      <RouteWithLayout
        path={routes.previewCheckoutPlans}
        component={PreviewCheckoutPlans}
        layout={PreviewCheckoutLayout}
        exact
        redirect={false}
        provider={OrderProvider}
      />
      <RouteWithLayout
        path={routes.previewCheckout.main}
        component={PreviewCheckoutMain}
        layout={PreviewCheckoutLayout}
        exact
        redirect={false}
        provider={OrderProvider}
      />
      <RouteWithLayout
        path={routes.plans.main}
        component={PlansMain}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.plans.edit}
        component={PlansEdit}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.plans.new}
        component={PlansNew}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.subscriptions.new}
        component={SubscriptionsNew}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.subscriptions.show}
        component={SubscriptionsShow}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.subscriptions.main}
        component={SubscriptionsMain}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.separateReport.main}
        component={SeparateReport}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.splitMain.main}
        component={SplitMain}
        layout={MainLayout}
        exact
        auth
      />
      <Route component={NotFound} exact path={routes.notFound} />
      <Route component={Forbidden} exact path={routes.forbidden} />
      <Redirect to={routes.notFound} />
    </Switch>
  )
}

export const routes = {
  root: '/',
  home: '/home',
  notFound: '/not-found',
  forbidden: '/forbidden',
  orders: include('/orders', {
    main: '',
    show: 'show/:orderId',
  }),
  charges: include('/charges', {
    new: 'new',
    main: '',
    billetInfo: 'billet-info',
  }),
  settings: include('/settings', {
    main: '',
    profile: 'profile',
    generateReport: 'generateReport',
    bankAccount: 'bank-accounts',
    notifications: 'notifications',
    implementation: 'implementation',
  }),
  payments: include('/payments/:type/:token', {
    main: '',
    details: 'details',
    credit: 'credit',
    creditPaid: 'credit/paid',
    billet: 'billet',
    pix: 'pix',
  }),
  withdraws: include('/withdraws', {
    new: 'new',
  }),
  balanceControls: '/balance-controls',
  statements: include('/statements', {
    main: '',
  }),
  checkoutPlans: '/checkout/plans/:accountToken',
  previewCheckoutPlans: '/previewcheckout/plans/:accountToken',
  checkout: include('/checkout/:type/:token', {
    main: '',
  }),
  previewCheckout: include('/previewcheckout/:type/:token', {
    main: '',
  }),
  plans: include('/plans', {
    main: '',
    new: 'new',
    edit: 'edit/:planId',
  }),
  subscriptions: include('/subscriptions', {
    new: 'new',
    main: '',
    show: ':subscriptionId',
  }),
  separateReport: include('/report', {
    new: 'new',
    main: '',
    show: ':subscriptionId',
  }),
  splitMain: include('/split', {
    new: 'new',
    main: '',
  }),
}

export default Routes
