import { Container, Page, Header } from 'components'
import { useState } from 'react'
import { Tab, Tabs, Grid } from '@material-ui/core'
import { useLocation } from 'react-router-dom'

import { Home, ChargesList } from './components/index'
import { routes } from 'Routes'

const ChargesMain = ({ match }) => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const [currentTab, setCurrentTab] = useState(
    queryParams.get('tab') != null ? queryParams.get('tab') : 'home',
  )

  const tabs = [
    { value: 'home', label: 'Início' },
    { value: 'charges', label: 'Cobrança' },
  ]

  const handleTabsChange = (event, value) => {
    setCurrentTab(value)
  }

  return (
    <Page title="Cobrança">
      <Container maxWidth="sm">
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Header name="Cobrança" route={routes.home} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Tabs
              onChange={handleTabsChange}
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="auto"
              value={currentTab}
              variant="scrollable"
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  onClick={(e) => handleTabsChange(e, tab.value)}
                />
              ))}
            </Tabs>
          </Grid>
          {currentTab === 'home' && <Home />}
          {currentTab === 'charges' && <ChargesList />}
        </Grid>
      </Container>
    </Page>
  )
}

export default ChargesMain
