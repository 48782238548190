import theme from './theme'
import menu from './menu'
import settingOptions from './settingOptions'
import paymentMethods from './paymentMethods'
import balanceControls from './balanceControls'
import payments from './payments'
import notifications from './notifications'
import withdraws from './withdraws'
import statements from './statements'
import subscriptions from './subscriptions'
import states from './states'
import functionalities from './functionalities'
import themeLead from './themeLead'

const constants = {
  theme,
  menu,
  settingOptions,
  paymentMethods,
  balanceControls,
  payments,
  notifications,
  withdraws,
  statements,
  subscriptions,
  states,
  functionalities,
  themeLead,
}

export default constants
