import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core'

import logo from 'images/logo-superfin.png'
import styles from './styles'

import LogoLovers from 'images/leadlovers-marca1.png'
import useAuth from 'hooks/useAuth'
import { AnimatedBox } from 'components'

const useStyles = makeStyles(styles)

const OpenScreen = ({ loaded, children, bgColor }) => {
  const classes = useStyles()
  const auth = useAuth()

  return (
    <>
      <div className={classes.bg}>
        <AnimatedBox
          div={[
            { id: 1, top: 0, left: 0 },
            { id: 2, top: 67, left: 555 },
            { id: 3, top: 80, left: 20 },
            { id: 4, top: 40, left: 230 },
          ]}
        />
        <figure className={classes.logo}>
          <img
            alt="Logo"
            src={
              auth?.account?.partnerGroup === 'leadlovers' ? LogoLovers : logo
            }
            width="100%"
          />
        </figure>
      </div>

      {loaded && children}
    </>
  )
}

OpenScreen.propTypes = {
  loaded: PropTypes.bool.isRequired,
  children: PropTypes.any,
}

export default OpenScreen
