const styles = (theme) => ({
  rootNew: {
    paddingRight: theme.spacing(30),
    paddingLeft: theme.spacing(30),
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0px',
      paddingLeft: '0px',
    },
  },
  menuNavigation: {
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
  },
  barCode: {
    backgroundColor: '#fff',
    borderRadius: theme.spacing(4),
  },
  roundedButton: {
    borderRadius: theme.spacing(4),
  },
})

export default styles
