import { Container, Page, Header } from 'components'
import { SubscriptionsForm } from './components'
import { makeStyles, Grid } from '@material-ui/core'

import styles from './styles'

import { routes } from 'Routes'

const useStyles = makeStyles(styles)

const SubscriptionsNew = () => {
  const classes = useStyles()

  return (
    <Page className={classes.root} title="Nova Assinatura">
      <Container maxWidth="sm">
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Header name="Nova Assinatura" route={routes.subscriptions.main} />
          </Grid>
          <Grid item lg={12} xs={12}>
            <SubscriptionsForm isEdit={false} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default SubscriptionsNew
