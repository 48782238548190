import {
  Briefcase as BriefcaseIcon,
  Check as CheckIcon,
  DollarSign as DollarSignIcon,
  Send as SendIcon,
  FileText as FileTextIcon,
  Loader as LoaderIcon,
  Link as LinkIcon,
  Layers as LayersIcon,
  ArrowDownCircle as ArrowDownCircleIcon,
  PieChart as PieChartIcon,
  Plus as PlusIcon,
  Settings as SettingsIcon,
} from 'react-feather'

import { routes } from 'Routes'

const ITEMS = {
  charges: {
    title: 'Cobranças',
    chargesMain: {
      name: 'Cobranças',
      icon: <DollarSignIcon size={16} color="#005534" />,
      color: '#005534',
      background: '#dff4ee',
      route: '/charges',
      view: true,
    },
    chargesNew: {
      name: 'C. Avulsa',
      icon: <LinkIcon size={16} color="#342182" />,
      color: '#342182',
      background: '#f0e5f4',
      route: '/charges/new',
      view: true,
    },
  },
  orders: {
    title: 'Transações',
    orderMain: {
      name: 'Transações',
      icon: <FileTextIcon size={16} color="#103556" />,
      color: '#103556',
      background: '#e2f4f9',
      route: '/orders',
      view: true,
    },
    orderR: {
      name: 'Recebido',
      icon: <CheckIcon size={16} color="#00A900" />,
      color: '#00A900',
      background: '#9FF89F',
      route: '/orders?status=PAID',
      view: true,
    },
    orderP: {
      name: 'Pendentes',
      icon: <SendIcon size={16} color="#342182" />,
      color: '#342182',
      background: '#f0e5f4',
      route: '/orders?status=NOT_PAID',
      view: true,
    },
    balanceControls: {
      name: 'Recebíveis',
      icon: <LoaderIcon size={16} color="#005534" />,
      color: '#005534',
      background: '#dff4ee',
      route: '/balance-controls',
      view: true,
    },
  },
  business: {
    title: 'Meu negócio',
    orderMain: {
      name: 'Relatório de C. Avulsa',
      icon: <PieChartIcon size={16} color="#005534" />,
      color: '#005534',
      background: '#dff4ee',
      route: '/report',
      view: true,
    },
  },
  subscriptions: {
    title: 'Assinatura',
    subscriptionsMain: {
      name: 'Assinaturas',
      icon: <DollarSignIcon size={16} color="#103556" />,
      color: '#103556',
      background: '#e2f4f9',
      route: '/subscriptions',
      view: true,
    },
    subscriptionsNew: {
      name: 'Nova Assinatura',
      icon: <DollarSignIcon size={16} color="#103556" />,
      color: '#103556',
      background: '#e2f4f9',
      route: '/subscriptions/new',
      view: true,
    },
  },
  plans: {
    title: 'Planos',
    plansMain: {
      name: 'Planos',
      icon: <LayersIcon size={16} color="#103556" />,
      color: '#103556',
      background: '#e2f4f9',
      route: '/plans',
      view: true,
    },
    plansNew: {
      name: 'Novo Plano',
      icon: <PlusIcon size={16} color="#103556" />,
      color: '#103556',
      background: '#e2f4f9',
      route: '/plans/new',
      view: true,
    },
  },
  others: {
    title: 'Outros',
    withDraws: {
      name: 'Sacar',
      icon: <ArrowDownCircleIcon size={16} color="#103556" />,
      color: '#103556',
      background: '#e2f4f9',
      route: '/withdraws/new',
      view: true,
    },
    bankAccounts: {
      name: 'Bancos',
      icon: <BriefcaseIcon size={16} color="#103556" />,
      color: '#103556',
      background: '#e2f4f9',
      route: '/settings/bank-accounts',
      view: true,
    },
    settings: {
      name: 'Configurações',
      icon: <SettingsIcon size={16} color="#103556" />,
      color: '#103556',
      background: '#e2f4f9',
      route: '/settings',
      view: true,
    },
  },
}

// const ROUTES = {
//   root: '/',
//   home: '/home',
//   notFound: '/not-found',
//   forbidden: '/forbidden',
//   orders: include('/orders', {
//     main: '',
//     show: 'show/:orderId',
//   }),
//   charges: include('/charges', {
//     new: 'new',
//     main: '',
//     billetInfo: 'billet-info',
//   }),
//   settings: include('/settings', {
//     main: '',
//     profile: 'profile',
//     bankAccount: 'bank-accounts',
//     notifications: 'notifications',
//     implementation: 'implementation',
//   }),
//   payments: include('/payments/:type/:token', {
//     main: '',
//     details: 'details',
//     credit: 'credit',
//     creditPaid: 'credit/paid',
//     billet: 'billet',
//     pix: 'pix',
//   }),
//   withdraws: include('/withdraws', {
//     new: 'new',
//   }),
//   balanceControls: '/balance-controls',
//   statements: include('/statements', {
//     main: '',
//   }),
//   checkoutPlans: '/checkout/plans/:accountToken',
//   previewCheckoutPlans: '/previewcheckout/plans/:accountToken',
//   checkout: include('/checkout/:type/:token', {
//     main: '',
//   }),
//   previewCheckout: include('/previewcheckout/:type/:token', {
//     main: '',
//   }),
//   plans: include('/plans', {
//     main: '',
//     new: 'new',
//     edit: 'edit/:planId',
//   }),
//   subscriptions: include('/subscriptions', {
//     new: 'new',
//     main: '',
//     show: ':subscriptionId',
//   }),
//   separateReport: include('/report', {
//     new: 'new',
//     main: '',
//     show: ':subscriptionId',
//   }),
// }

const functionalities = {
  ITEMS,
}

export default functionalities
