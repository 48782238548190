const styles = (theme) => ({
  boxButton: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(8),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
    transition: 'all 500ms ease',
    padding: theme.spacing(8),
    [theme.breakpoints.between('xs', 'sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& > .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'start',
    },
  },

  circle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: theme.spacing(20),
    height: theme.spacing(20),
    [theme.breakpoints.between('xs', 'sm')]: {
      width: theme.spacing(20),
      height: theme.spacing(20),
    },
    zIndex: 1,
  },
  textButton: {
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
    },
    zIndex: 1,
  },
})

export default styles
