const styles = (theme) => ({
  root: {
    height: '60px',
    backgroundColor: 'transparent',
  },
  logo: {
    width: '180px',
  },
  toolbar: {
    minHeight: 68,
  },
  button: {
    borderRadius: theme.spacing(20),
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    backgroundColor: '#a804bc',
    color: 'white',
    '&:hover': {
      backgroundColor: '#a804bc',
    },
  },
})

export default styles
