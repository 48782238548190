const styles = (theme) => ({
  menu: {
    borderRadius: '70px',
    alignItems: 'center',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(10) + 1,
  },
  activeMenu: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '40px',
    alignItems: 'flex-start',
    width: 200,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  buttonExtended: {
    textTransform: 'capitalize',
    borderRadius: '50px !important',
    padding: theme.spacing(2),
  },
  button: {
    height: '64px',
    borderRadius: '50%',
  },
  title: {
    paddingLeft: '12px',
    textAlign: 'left',
  },
  buttonLabel: {
    justifyContent: 'flex-start',
  },
  menuButton: {
    backgroundColor: theme.palette.background.default,
  },
  active: {
    color: '#FFF',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.black,
    },
  },
})

export default styles
