import { useState } from 'react'

import {
  Grid,
  Box,
  Typography,
  TextField,
  makeStyles,
  IconButton,
  Divider,
  Button,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import {
  Minus as MinusIcon,
  Plus as PlusIcon,
  Trash as TrashIcon,
} from 'react-feather'
import { MoneyInput } from 'components'
import { useForm, Controller, useFieldArray } from 'react-hook-form'

import useSnackbar from 'hooks/useSnackbar'
import { useHistory } from 'react-router-dom'

import styles from './styles'
import { routes } from 'Routes'
import helpers from 'helpers'
import schema from './schema'
import * as service from 'service'
import DialogChargesBillet from '../DialogChargesBillet'

import theme from 'theme'

const useStyles = makeStyles(styles)

const ChargesForm = () => {
  const classes = useStyles()
  const history = useHistory()
  const snackbar = useSnackbar()
  const [total, setTotal] = useState(0)
  const [openDialog, setOpenDialog] = useState(false)
  const [chargesData, setChargesData] = useState({})

  const { control, handleSubmit, errors, getValues, setValue } = useForm({
    validationSchema: schema,
    defaultValues: {
      payerName: '',
      payerEmail: '',
      expirationDate: new Date(),
      code: '',
      description: '',
      products: [{}],
      billet: false,
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'products',
  })

  const minusUnity = (index) => {
    let number = parseInt(getValues(`products[${index}].quantity`)) || 0
    if (number > 0) {
      setValue(`products[${index}].quantity`, --number)
    } else {
      setValue(`products[${index}].quantity`, 0)
    }
    recalculateTotal()
  }

  const plusUnity = (index) => {
    let number = parseInt(getValues(`products[${index}].quantity`)) || 0
    setValue(`products[${index}].quantity`, ++number)
    recalculateTotal()
  }

  const recalculateTotal = () => {
    let partialTotal = 0
    fields.forEach((product, index) => {
      let productPrice = parseFloat(getValues(`products[${index}].price`)) || 0
      let productUnity = parseInt(getValues(`products[${index}].quantity`)) || 0
      partialTotal += productPrice * productUnity
    })
    setTotal(partialTotal)
  }

  const getCurrentDate = () => {
    const today = new Date()
    const year = today.getFullYear()
    let month = today.getMonth() + 1
    let day = today.getDate()

    // Adiciona zero na frente se o mês ou dia for menor que 10
    if (month < 10) {
      month = `0${month}`
    }
    if (day < 10) {
      day = `0${day}`
    }

    return `${year}-${month}-${day}`
  }

  const onSubmit = async (data) => {
    if (data.billet) {
      setChargesData(data)
      setOpenDialog(true)
    } else {
      try {
        await service.intermediador.charges.create({
          charge: {
            payerName: data.payerName,
            payerEmail: data.payerEmail,
            expirationDate: helpers.formatters.date(data.expirationDate),
            code: data.code,
            description: data.description,
            products: data.products,
            price: total.toString(),
          },
        })

        snackbar.open({
          message: `Cobrança criada com sucesso`,
          variant: 'success',
        })

        history.push({ pathname: routes.charges.main, search: '?tab=charges' })
      } catch (error) {
        snackbar.open({
          message: 'Erro ao criar cobrança',
          variant: 'error',
        })
      }
    }
  }

  return (
    <>
      <form id="chargeForm" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box mt={theme.spacing(10)}>
              <Typography color="textPrimary" variant="h6">
                Informações do cliente
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant="body1" color="textSecondary">
              Nome*
            </Typography>
            <Controller
              as={
                <TextField
                  fullWidth
                  type="text"
                  color="primary"
                  error={!!errors.payerName}
                  helperText={errors?.payerName?.message}
                  size="small"
                />
              }
              control={control}
              name="payerName"
              mode="onBlur"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant="body1" color="textSecondary">
              E-mail*
            </Typography>
            <Controller
              as={
                <TextField
                  fullWidth
                  type="text"
                  color="primary"
                  size="small"
                  error={!!errors.payerEmail}
                  helperText={errors?.payerEmail?.message}
                />
              }
              control={control}
              name="payerEmail"
              mode="onBlur"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box mt={theme.spacing(10)}>
              <Typography color="textPrimary" variant="h6">
                Informações de cobrança
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Typography variant="body1" color="textSecondary">
              Código*
            </Typography>
            <Controller
              as={
                <TextField
                  type="text"
                  color="primary"
                  error={!!errors.code}
                  size="small"
                  helperText={errors?.code?.message}
                  fullWidth
                />
              }
              control={control}
              name="code"
              mode="onBlur"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Typography variant="body1" color="textSecondary">
              Data de vencimento*
            </Typography>
            <Controller
              as={
                <TextField
                  fullWidth
                  type="date"
                  format="DD/MM/yyyy"
                  size="small"
                  helperText={errors?.expirationDate?.message}
                  error={!!errors.expirationDate}
                  inputProps={{ min: getCurrentDate(), max: '2999-01-01' }}
                  inputVariant="outlined"
                />
              }
              control={control}
              name="expirationDate"
              mode="onBlur"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="body1" color="textSecondary">
              Descrição*
            </Typography>
            <Controller
              as={
                <TextField
                  type="text"
                  color="primary"
                  error={!!errors.description}
                  helperText={errors?.description?.message}
                  size="small"
                  fullWidth
                />
              }
              control={control}
              name="description"
              mode="onBlur"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box mt={theme.spacing(10)}>
              <Typography color="textPrimary" variant="h6">
                Carrinho
              </Typography>
            </Box>
          </Grid>
          {fields.map((product, index) => {
            return (
              <Grid item xs={12} sm={12} md={12} lg={12} key={product.id}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid item mt={2}>
                      {/* <Divider /> */}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box mt={1}>
                      <Typography variant="body1" color="textSecondary">
                        Nome*
                      </Typography>
                      <Controller
                        as={
                          <TextField
                            size="small"
                            type="text"
                            color="primary"
                            error={!!errors.products?.[index]?.name}
                            helperText={
                              errors?.products?.[index]?.name?.message
                            }
                            fullWidth
                          />
                        }
                        control={control}
                        name={`products[${index}].name`}
                        mode="onBlur"
                        defaultValue={product.name || ''}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="body1" color="textSecondary">
                      Descrição*
                    </Typography>
                    <Controller
                      as={
                        <TextField
                          size="small"
                          type="text"
                          color="primary"
                          error={!!errors.products?.[index]?.description}
                          helperText={
                            errors?.products?.[index]?.description?.message
                          }
                          fullWidth
                        />
                      }
                      control={control}
                      name={`products[${index}].description`}
                      mode="onBlur"
                      defaultValue={product.description || ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Typography variant="body1" color="textSecondary">
                      Preço unitário (R$)*
                    </Typography>
                    <Controller
                      as={
                        <TextField
                          size="small"
                          type="text"
                          color="primary"
                          error={!!errors.products?.[index]?.price}
                          helperText={errors?.products?.[index]?.price?.message}
                          fullWidth
                          InputProps={{
                            inputComponent: MoneyInput,
                          }}
                          onBlur={recalculateTotal}
                        />
                      }
                      control={control}
                      name={`products[${index}].price`}
                      mode="onBlur"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    className={classes.unityBox}
                  >
                    <IconButton
                      className={classes.unityButton}
                      onClick={() => minusUnity(index)}
                    >
                      <MinusIcon />
                    </IconButton>
                    <Controller
                      as={
                        <TextField
                          label="Unidade(s)"
                          size="small"
                          type="text"
                          color="primary"
                          error={!!errors.products?.[index]?.quantity}
                          helperText={
                            errors?.products?.[index]?.quantity?.message
                          }
                          onBlur={recalculateTotal}
                        />
                      }
                      control={control}
                      name={`products[${index}].quantity`}
                      mode="onBlur"
                      defaultValue={product.quantity || 0}
                    />
                    <IconButton
                      className={classes.unityButton}
                      onClick={() => plusUnity(index)}
                    >
                      <PlusIcon />
                    </IconButton>
                  </Grid>

                  {index > 0 ? (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <IconButton
                        className={classes.trashButton}
                        onClick={() => {
                          remove(index)
                          setTimeout(() => {
                            recalculateTotal()
                          }, 200)
                        }}
                      >
                        <TrashIcon />
                      </IconButton>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            )
          })}

          <Grid item sm={12} xs={12}>
            <Box mt={theme.spacing(10)}>
              <Typography
                component="span"
                color="textPrimary"
                className={classes.clickableText}
                onClick={() => append({ name: '', price: '', quantity: '' })}
              >
                + Adicionar mais produtos
              </Typography>
              <Box mt={1}>
                <Typography color="textPrimary" className={classes.boldText}>
                  Total do carrinho: R${' '}
                  {total.toFixed(2).toString().replace('.', ',')}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item sm={12} xs={12}>
            <FormControlLabel
              label="Gerar cobrança com boleto"
              style={{ color: theme.palette.text.primary }}
              control={
                <Controller
                  as={<Checkbox value={true} />}
                  control={control}
                  name="billet"
                  mode="onBlur"
                />
              }
            />
          </Grid>
        </Grid>
      </form>
      <Box my={theme.spacing(10)} display="flex" justifyContent="flex-start">
        <Box pr={theme.spacing(10)}>
          <Button
            type="button"
            onClick={() => history.push(routes.charges.main)}
          >
            Voltar
          </Button>
        </Box>
        <Box>
          <Button
            form="chargeForm"
            color="primary"
            variant="contained"
            type="submit"
          >
            Salvar e gerar link de cobrança
          </Button>
        </Box>
        <DialogChargesBillet
          open={openDialog}
          setOpen={setOpenDialog}
          chargesData={chargesData}
        />
      </Box>
    </>
  )
}

export default ChargesForm
