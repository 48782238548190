import { makeStyles, Grid } from '@material-ui/core'

import { Container, Header, Page, LoadingFeedback } from 'components'
import { SubscriptionInfos, SubscriptionPayments } from './components'

import useFetch from 'hooks/useFetch'
import { routes } from 'Routes'

import styles from './styles'
import * as service from 'service'

const useStyles = makeStyles(styles)

const SubscriptionsShow = ({ match }) => {
  const classes = useStyles()

  const { subscriptionId } = match.params
  const { response, isLoading } = useFetch(
    service.intermediador.subscriptions.show,
    {
      subscriptionId,
    },
  )

  const requestInvoice = useFetch(service.intermediador.invoices.get, {
    subscriptionId,
  })

  return (
    <Page className={classes.root} title="Assinatura">
      <LoadingFeedback open={isLoading} />
      <Container maxWidth="md">
        <Grid container={8}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Header name="Assinatura" route={routes.subscriptions.main} />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <SubscriptionInfos
              isLoading={isLoading}
              subscription={response?.data?.subscription}
            />
          </Grid>
          {/* <Grid item lg={12} md={12} sm={12} xs={12}>
            <SubscriptionPayments
              isLoading={isLoading}
              subscription={response?.data?.subscription}
              invoices={requestInvoice?.response?.data?.invoices}
            />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  )
}

export default SubscriptionsShow
