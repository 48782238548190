import { Container, Grid } from '@material-ui/core'

import { Page, Header } from 'components'
import ChargesForm from './components/ChargesForm'

import { routes } from 'Routes'

const ChargesNew = () => {
  return (
    <Page title="Nova cobrança">
      <Container maxWidth="sm" spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Header name="Nova cobrança avulsa" route={routes.charges.main} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ChargesForm />
        </Grid>
      </Container>
    </Page>
  )
}

export default ChargesNew
