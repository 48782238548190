import React, { useState, useRef } from 'react'
import { Typography, Button, Box, Grid } from '@material-ui/core'
import { SelectColors } from '../index'

import { Check as CheckIcon } from 'react-feather'

import {
  Info as InfoIcon,
  AlertCircle as AlertIcon,
  XCircle as XIcon,
} from 'react-feather'

import helpers from 'helpers'

const ColorsTheme = ({ changeColor, customPalette }) => {
  const selectColorsRef = useRef()
  const [selectedBoxColor, setSelectedBoxColor] = useState(null)
  const [formValues, setFormValues] = useState({
    palette: {
      primary: {
        contrastText: customPalette.palette.white,
        main: customPalette.palette.primary.main,
      },
      secondary: {
        contrastText: customPalette.palette.white,
        main: customPalette.palette.secondary.main,
      },
      success: {
        contrastText: customPalette.palette.white,
        main: customPalette.palette.success.main,
      },
      info: {
        contrastText: customPalette.palette.white,
        main: customPalette.palette.info.main,
      },
      warning: {
        contrastText: customPalette.palette.white,
        main: customPalette.palette.warning.main,
      },
      error: {
        contrastText: customPalette.palette.white,
        main: customPalette.palette.error.main,
      },
      text: {
        primary: customPalette.palette.text.primary,
        secondary: customPalette.palette.text.secondary,
        link: customPalette.palette.text.link,
      },
      background: {
        default: customPalette.palette.background.default,
        paper: customPalette.palette.background.paper,
      },
      line: {
        default: customPalette.palette.line.default,
      },
      action: {
        active: customPalette.palette.action.active,
      },
    },
  })

  const Card = ({ children, styles, name, type = null }) => {
    return (
      <Button
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: styles,
          borderRadius: '20px',
          border: '1px solid silver',
          padding: '8px',
          cursor: 'pointer',
          width: '100%',
        }}
        onClick={() => handleBoxClick(name, type)}
      >
        {children}
      </Button>
    )
  }

  const handleSaveButtonClick = () => {
    changeColor(formValues)
  }

  const applyColor = (data) => {
    const key = Object.keys(data)[0]
    const value = data[key]
    const keys = Object.keys(value)

    const newColor = {
      ...formValues.palette[key],
      [keys]: value[keys[0]],
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      palette: {
        ...prevValues.palette,
        [key]: newColor,
      },
    }))
  }

  const handleBoxClick = (key, subKey) => {
    const selectedColor = formValues.palette[key][subKey]

    setSelectedBoxColor([selectedColor, key, subKey])

    selectColorsRef.current.openDialog()
  }

  return (
    <form noValidate autoComplete="off" style={{ width: '300px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography color="textPrimary" variant="h6">
            Design
          </Typography>
          <Typography color="textPrimary" variant="body2">
            Defina os padrões de cores para todos os elementos do carrinho de
            pagamentos
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography color="textPrimary" variant="body1">
            Cores base
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card
            styles={formValues.palette.primary.main}
            name="primary"
            type="main"
          >
            <Typography
              color={formValues.palette.primary.main}
              variant="body2"
              align="center"
            >
              primária
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card
            styles={formValues.palette.secondary.main}
            name="secondary"
            type="main"
          >
            <Typography color="textPrimary" variant="body2" align="center">
              secundária
            </Typography>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography color="textPrimary" variant="body1">
            Cores dos status
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Card
            name="success"
            type="main"
            styles={formValues.palette.success.main}
          >
            <CheckIcon size={14} />
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Card name="info" type="main" styles={formValues.palette.info.main}>
            <InfoIcon size={14} />
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Card
            name="warning"
            type="main"
            styles={formValues.palette.warning.main}
          >
            <AlertIcon size={14} />
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Card name="error" type="main" styles={formValues.palette.error.main}>
            <XIcon size={14} />
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography color="textPrimary" variant="body1">
            Cores do background
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card
            styles={formValues.palette.background.default}
            name="background"
            type="default"
          >
            <Typography color="textPrimary" variant="body2" align="center">
              Background primária
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card
            styles={formValues.palette.background.paper}
            name="background"
            type="paper"
          >
            <Typography color="textPrimary" variant="body2" align="center">
              Background secundária
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography color="textPrimary" variant="body1">
            Linhas
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card
            styles={formValues.palette.line.default}
            name="line"
            type="default"
          >
            <Typography color="textPrimary" variant="body2" align="center">
              Cor
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography color="textPrimary" variant="body1">
            Texto
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card
            styles={formValues.palette.text.primary}
            name="text"
            type="primary"
          >
            <Typography color="textPrimary" variant="body2" align="center">
              Cor Primária
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card
            styles={formValues.palette.text.secondary}
            name="text"
            type="secondary"
          >
            <Typography color="textPrimary" variant="body2" align="center">
              Cor Secundária
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography color="textPrimary" variant="body1">
            Ícones
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card
            styles={formValues.palette.action.active}
            name="action"
            type="active"
          >
            <Typography color="textPrimary" variant="body2" align="center">
              Cor
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveButtonClick}
            style={{ width: '100%', marginTop: '20px' }}
          >
            Aplicar
          </Button>
        </Grid>
      </Grid>
      <SelectColors
        ref={selectColorsRef}
        defaultColor={selectedBoxColor}
        applyColor={applyColor}
      />
    </form>
  )
}

export default ColorsTheme
