import Boleto from '../images/image-boleto.svg'
import Card from '../images/image-creditcard.svg'
import Pix from '../images/image-pix.svg'

const MASTERCARD_ID = 1
const VISA_ID = 2
const AMEX_ID = 3
const ELO_ID = 4
const HIPERCARD_ID = 5
//const BILLET_ID = 6
//const PIX_ID = 7

const CARD_METHODS = [MASTERCARD_ID, VISA_ID, AMEX_ID, ELO_ID, HIPERCARD_ID]

const METHODS = [
  {
    id: 2,
    name: 'Boleto',
    image: <img src={Boleto} width="28px" style={{ marginRight: 12 }} />,
  },
  {
    id: 3,
    name: 'Cartão de crédito',
    image: <img src={Card} width="28px" style={{ marginRight: 12 }} />,
  },
  {
    id: 4,
    name: 'PIX',
    image: <img src={Pix} width="28px" style={{ marginRight: 12 }} />,
  },
]

const paymentMethods = {
  CARD_METHODS,
  METHODS,
}

export default paymentMethods
