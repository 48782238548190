import { Link as RouterLink } from 'react-router-dom'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import {
  AppBar,
  Toolbar,
  Box,
  makeStyles,
  Button,
  Hidden,
} from '@material-ui/core'
import { MessageCircle as MessageCircleIcon } from 'react-feather'

import { Account } from './components'

import { Container } from 'components'

import styles from './styles'
import Logo from 'images/logo_superfin.png'
import LogoLovers from 'images/leadlovers-marca.svg'
import { routes } from 'Routes'

const useStyles = makeStyles(styles)

const TopBar = ({ className, onMobileNavOpen, logo, ...rest }) => {
  const classes = useStyles()

  return (
    <AppBar
      elevation={0}
      position="relative"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="md">
        <Toolbar className={classes.toolbar} disableGutters={true}>
          <Box>
            <RouterLink to={routes.home}>
              {logo === 'leadlovers' ? (
                <img
                  alt="Logo"
                  src={LogoLovers}
                  className={classes.logo}
                  width="100%"
                />
              ) : (
                <img
                  alt="Logo"
                  src={Logo}
                  className={classes.logo}
                  width="100%"
                />
              )}
            </RouterLink>
          </Box>
          <Box ml={2} flexGrow={1} />
          <Hidden smDown>
            <Box ml={2}>
              <Button
                onClick={() =>
                  window.open(
                    'https://docs.google.com/forms/d/e/1FAIpQLSdd8mF98s-75D5P6Yp9Nd-V022wup4U4speXKD_Fr5B7Xh4VA/viewform?usp=sf_link',
                  )
                }
                className={classes.button}
              >
                Sugestões
                <MessageCircleIcon size={16} className={classes.paddingLeft} />
              </Button>
            </Box>
          </Hidden>
          <Box ml={2}>
            <Account />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
}

export default TopBar
