import { colors } from '@material-ui/core'

const white = '#FFFFFF'
const black = '#000000'
const lightPalette = {
  black,
  white,
  primary: {
    contrastText: white,
    dark: '#005a00',
    main: '#00A900',
    light: '#6ac361',
    superLight: '#e5f4e3',
  },
  secondary: {
    contrastText: white,
    dark: '#342182',
    main: '#9651b8',
    light: '#c096d6',
    superLight: '#f0e5f4',
  },
  teriary: {
    contrastText: white,
    dark: '#ffad2f',
    main: '#FFAC2F',
    light: '#ffdc3e',
    superLight: '#fffee8',
  },
  support: {
    contrastText: white,
    dark: '#005534',
    main: '#44be9d',
    light: '#b1e3d4',
    superLight: '#dff4ee',
  },
  additional: {
    contrastText: white,
    dark: '#103556',
    main: '#235078',
    light: '#63bbdb',
    superLight: '#e2f4f9',
  },
  newSucess: {
    sucessOne: '#00A900',
    sucessTwo: '#9FF89F',
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  newInfo: {
    infoOne: '#1084AC',
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  newWarning: {
    warningOne: '#B71C1C',
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: '#235078',
    menu: '#546E7A',
    secondary: '#598CB9',
    link: colors.blue[600],
  },
  background: {
    default: '#F2F6F8',
    paper: '#FFF',
  },
  card: {
    default: '#052846',
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[300],
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161',
  },
  action: {
    active: '#e2f4f9',
    hover: '#e2f4f9',
    selected: 'rgba(0, 0, 0, 0.08)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    disabledOpacity: 0.38,
    focus: '#BCE4FF',
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
  },
}

const palette = lightPalette

export default palette
