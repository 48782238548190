import { Container, Page, Header } from 'components'
import { PlansForm } from './components'
import { makeStyles, Grid } from '@material-ui/core'

import { routes } from 'Routes'

import styles from './styles'

const useStyles = makeStyles(styles)

const PlansNew = () => {
  const classes = useStyles()

  return (
    <Page className={classes.root} title="Planos Recorrentes">
      <Container maxWidth="sm">
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Header name="Planos recorrentes" route={routes.plans.main} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <PlansForm isEdit={false} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default PlansNew
