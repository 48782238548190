import {
  Grid,
  makeStyles,
  TablePagination,
  Typography,
  useMediaQuery,
  Box,
  Button,
} from '@material-ui/core'

import React, { useMemo } from 'react'

import { User as UserIcon } from 'react-feather'

import {
  Container,
  Page,
  FilterRow,
  Filters,
  LoadingFeedback,
} from 'components'
import { ListCard, Header } from 'components'

import usePagination from 'hooks/usePagination'
import useFilter from 'hooks/useFilter'

import { useHistory } from 'react-router-dom'

import * as service from 'service'
import { reverse } from 'named-urls'

import useFetch from 'hooks/useFetch'

import styles from './styles'
import theme from 'theme'
import { routes } from 'Routes'
import helpers from 'helpers'
import constants from 'constants/index'

const useStyles = makeStyles(styles)

const SubscriptionsMain = React.memo(() => {
  const classes = useStyles()
  const filter = useFilter()

  const history = useHistory()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { page, perPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination(9)

  const { response, isLoading } = useFetch(
    service.intermediador.subscriptions.get,
    { ...{ ...filter.filters }, page, perPage },
    [page, perPage, filter.filters],
  )

  const subscriptionsByDate = useMemo(() => {
    const subscriptionsMap = new Map()
    response?.data?.subscriptions &&
      response?.data?.subscriptions.forEach((value) => {
        const dataKey = helpers.formatters.date(value?.createdAt)
        if (!subscriptionsMap.has(dataKey)) {
          subscriptionsMap.set(dataKey, [])
        }
        subscriptionsMap.get(dataKey).push(value)
      })
    return subscriptionsMap
  }, [response?.data?.subscriptions])

  return (
    <Page className={classes.root} title="Transações">
      <LoadingFeedback open={isLoading} />
      <Container maxWidth="md">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Header name="Assinaturas" route={routes.home} />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box
              display="flex"
              justifyContent="end"
              alignItems="center"
              style={{ height: '100%' }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => {
                  history.push(routes.subscriptions.new)
                }}
              >
                Nova Assinatura
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FilterRow filter={filter} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {subscriptionsByDate &&
              Array.from(subscriptionsByDate).map(([byDate, data]) => (
                <Box key={data[0]?.id} className={classes.container}>
                  <Box mt={2} mb={2} className={classes.center}>
                    <Typography variant="body1" color="textPrimary">
                      {byDate}
                    </Typography>
                  </Box>
                  {data.map((value) => (
                    <ListCard
                      key={value?.id}
                      sendToDetails={false}
                      pulseStatus={value?.status}
                      pulseEffect={value}
                      iconByStatus={{
                        icon: (
                          <UserIcon
                            style={{
                              color: '#235078',
                            }}
                          />
                        ),
                        color: theme.palette.white,
                      }}
                      title={value?.customer?.name}
                      subtitle={helpers.subscriptions.subscriptionStatus(
                        value?.status,
                      )}
                      hour={helpers.formatters.hours(value?.createdAt)}
                      value={helpers.subscriptions.valueLabel(
                        value.subscriptionPlans[0].price,
                      )}
                      route={reverse(routes?.subscriptions?.show, {
                        subscriptionId: value?.id,
                      })}
                    />
                  ))}
                </Box>
              ))}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
              mt={2}
              display="flex"
              justifyContent="flex-end"
              className={classes.pagination}
            >
              <TablePagination
                component="div"
                count={response?.data?.meta?.totalCount || 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[9, 12, 15, 18]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            </Box>
          </Grid>
          <Filters filter={filter}>
            <select textfieldinput="true" label="Status" name="status">
              <option value={constants.subscriptions.STATUSES.NOT_PAID_STATUS}>
                {constants.subscriptions.STATUSES_LABELS.NOT_PAID_LABEL}
              </option>
              <option
                value={
                  constants.subscriptions.STATUSES.WAITING_ACTIVATION_STATUS
                }
              >
                {
                  constants.subscriptions.STATUSES_LABELS
                    .WAITING_ACTIVATION_LABEL
                }
              </option>
              <option value={constants.subscriptions.STATUSES.ACTIVE_STATUS}>
                {constants.subscriptions.STATUSES_LABELS.ACTIVE_LABEL}
              </option>
              <option value={constants.subscriptions.STATUSES.OVERDUED_STATUS}>
                {constants.subscriptions.STATUSES_LABELS.OVERDUED_LABEL}
              </option>
              <option value={constants.subscriptions.STATUSES.CANCELED_STATUS}>
                {constants.subscriptions.STATUSES_LABELS.CANCELED_LABEL}
              </option>
            </select>
            <input
              datepickerinput="true"
              label="Data de criação inicial"
              name="createdAtGt"
              disableFuture
              data-target="createdAtLt"
              data-min
            />
            <input
              datepickerinput="true"
              label="Data de criação final"
              name="createdAtLt"
              disableFuture
              data-target="createdAtGt"
              data-max
            />
          </Filters>
        </Grid>
      </Container>
    </Page>
  )
})

export default SubscriptionsMain
